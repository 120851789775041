.career-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
}

.career-image-container {
    width: 100%;
    max-width: 700px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 10px;
}

.career-image {
    width: 100%;
    position: relative;
    height: auto; /* Allow the image to set its height based on its width */
}

.career-image img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* This will ensure the full image is visible without cropping */
    transition: opacity 1s ease-in-out;
    border-radius: 10px;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

.overlay-text {
    color: white;
    font-size: 24px;
    padding: 10px;
}

.career-image:hover .image-overlay {
    opacity: 1;
}

.career-content {
    max-width: 700px;
    padding: 0 15px;
}

.career-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.career-content p {
    font-size: 1.1rem;
    margin-bottom: 10px;
    line-height: 1.5;
    color: #555;
}

.career-content h2 {
    margin-top: 40px;
    font-size: 2rem;
    color: #333;
}

.career-content a {
    color: #007BFF;
    text-decoration: none;
}

.career-content a:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
    .career-content h1 {
        font-size: 2rem;
    }

    .career-content h2 {
        font-size: 1.5rem;
    }

    .career-content p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .career-content h1 {
        font-size: 1.5rem;
    }

    .career-content h2 {
        font-size: 1.2rem;
    }

    .career-content p {
        font-size: 0.9rem;
    }

    .overlay-text {
        font-size: 1.2rem;
    }
}
