
   .livescroll-carousel {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .livescroll-carousel h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .livescroll-container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  
  .livescroll-items {
    display: flex;
    width: fit-content;
    animation: scroll var(--animation-duration, 30s) linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-1 * var(--scroll-width)));
    }
  }
  
  .livescroll-item {
    flex: 0 0 auto;
    width: 120px;
    text-align: center;
    margin: 0 10px;
  }
  
  .livescroll-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .livescroll-item p {
    margin-top: 10px;
    font-size: 14px;
  }