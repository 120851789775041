.footer {
  background-color: #222;
  color: #fff;
  padding: 40px 0 20px;
  font-family: 'Playfair Display', serif;
  position: absolute;
  left: 0;
  width: 100%;
}
.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}
.footer-section {
  flex: 1;
  margin: 0 20px;
  min-width: 200px;
}
.footer-section p {
  color: white;
}
.footer-section h3 {
  color: #DAA520;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.footer-links {
  list-style-type: none;
  padding: 0;
}
.footer-links li {
  margin-bottom: 10px;
}
.footer-links a {
  color: #fff;
  padding: 3px;
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-links a:hover {
  color: #DAA520;
}
.social-icons {
  display: flex;
  gap: 15px;
  justify-content: center;
}
.social-icon {
  color: #fff;
  font-size: 1.5em;
  transition: color 0.3s ease;
}
.social-icon:hover {
  color: #DAA520;
}
.footer-section {
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #444;
}
.footer-bottom {
  text-align: center;
  position: relative;
}
.footer-bottom p {
  color: white;
}
.order-options {
  position: absolute;
  background-color: gold;
  border: 1px solid #ddd;
  border-radius: 4px;
  top: 21px;
  right: 0;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.order-options-footer {
  display: grid;
  position: absolute;
  background-color: gold;
  border: 1px solid #ddd;
  border-radius: 4px;
  top: 21px;
  left: 3rem;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
}
.order-options-footer a {
  color: black;
  padding: 3px;
  text-decoration: none;
  transition: color 0.3s ease;
}
.order-options-footer a:hover {
  color: white;
}
.order-options a {
  display: block;
  color: #333;
  padding: 3px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}
.order-options a:hover {
  background-color: #f0f0f0;
}
.notification-bar {
  position: absolute;
  top: 85%;
  left: 98.75%;
  transform: translateY(-50%);
  right: 10px;
  width: fit-content;
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
}

/* Add this to Footer.css */
/* Add this to Footer.css */
.brands-dropdown {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Ensures the dropdown appears on top of other elements */
}

.brands-dropdown a {
  padding: 5px 10px;
  text-decoration: none;
  color: #333;
}

.brands-dropdown a:hover {
  background-color: #f0f0f0;
}


@keyframes cityscape {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}
@keyframes glow {
  0%, 100% { filter: drop-shadow(0 0 5px #00ffff) drop-shadow(0 0 10px #00ffff); }
  50% { filter: drop-shadow(0 0 10px #ff00ff) drop-shadow(0 0 20px #ff00ff); }
}
.footer-animation {
  position: relative;
  overflow: hidden;
  height: 200px;
  margin-top: 20px;
  perspective: 1000px;
  background: linear-gradient(to bottom, #000033, #000066);
}
.cityscape {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 100px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100"><path d="M0 100 L0 60 L10 60 L10 40 L20 40 L20 60 L30 60 L30 50 L40 50 L40 70 L50 70 L50 40 L60 40 L60 80 L70 80 L70 50 L80 50 L80 70 L90 70 L90 40 L100 40 L100 100 Z" fill="%23001a33" /><path d="M100 100 L100 30 L110 30 L110 60 L120 60 L120 40 L130 40 L130 70 L140 70 L140 50 L150 50 L150 60 L160 60 L160 80 L170 80 L170 40 L180 40 L180 60 L190 60 L190 50 L200 50 L200 100 Z" fill="%23002b4d" /></svg>') repeat-x;
  animation: cityscape 20s linear infinite;
}
.scooter-container {
  position: absolute;
  left: -100%;
  top: 50%;
  display: flex;
  align-items: center;
  animation: move-scooter 15s ease-in-out infinite;
  transform-style: preserve-3d;
}
.scooter {
  font-size: 80px;
  animation: float 2s ease-in-out infinite, glow 4s linear infinite;
  filter: drop-shadow(0 0 10px rgba(255,215,0,0.7));
}
.smoke-trail {
  position: absolute;
  left: 100px;
  top: 50%;
  width: 50px;
  height: 20px;
}
.smoke-particle {
  position: absolute;
  font-size: 24px;
  opacity: 0;
  animation: smoke-effect 2s ease-out infinite;
}
.text-container {
  position: absolute;
  left: -150px;
  top: -10px;
  transform-origin: bottom left;
  animation: text-3d 15s ease-in-out infinite;
  transform-style: preserve-3d;
}
.text-container span {
  font-size: 12px;
  font-weight: bold;
  color: #00ffff;
  text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff;
  white-space: nowrap;
  font-family: 'Audiowide', cursive;
  letter-spacing: 2px;
}
@keyframes move-scooter {
  0%, 100% { left: -100%; transform: translateZ(-1000px) rotateY(45deg); }
  10%, 90% { left: 50%; transform: translateZ(0) rotateY(0deg); }
  50% { transform: translateZ(10px) rotateY(-10deg); }
}
@keyframes smoke-effect {
  0% { opacity: 0.7; transform: translate(0, 0) scale(1) rotate(0deg); }
  100% { opacity: 0; transform: translate(20px, -50px) scale(3) rotate(360deg); }
}
@keyframes text-3d {
  0%, 5%, 95%, 100% { 
    opacity: 0;
    transform: scale(0) rotateX(-90deg) translateZ(-200px);
  }
  10%, 90% { 
    opacity: 1;
    transform: scale(1) rotateX(0deg) translateZ(0);
  }
  50% {
    transform: scale(1.3) rotateX(20deg) translateZ(100px);
  }
}