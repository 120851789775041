:root {
  --primary-color: #120f0b;
  --secondary-color:rgba(232, 221, 213, 0.831);
  
  --accent-color: #ffc107;
  --text-color: #333;
  --transition: all 0.3s ease;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  /* min-height: 100vh; */
}

.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
}

.main-content {
  /* min-height: calc(100vh -77.8px -390px); */
  min-height: 100vh;
  flex: 1;
  /* padding: 2rem; */
  /* min-height: calc(100vh - 27px -703px); */
  /* margin-top: 395px; */
}
