:root {
    --primary-color: #ededed;
    --secondary-color: #e3e3e3;
    --text-color: #000000;
    --input-bg-color: #f9f9f9;
    --input-border-color: #cccccc;
    --submit-bg-color: #29178b;
    --submit-text-color: #ffffff;
    --form-width: 100%;
    --max-width: 600px;
    --border-radius: 4px;
    --transition: all 0.3s ease;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: var(--primary-color);
  }
  
  form {
    background-color: var(--secondary-color);
    padding: 20px;
    margin: 20px auto;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: var(--max-width);
    width: var(--form-width);
  }
  
  label {
    display: block;
    margin-bottom: 8px;
    color: var(--text-color);
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);
    background-color: var(--input-bg-color);
    transition: var(--transition);
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    border-color: var(--submit-bg-color);
    outline: none;
  }
  
  textarea {
    height: 150px;
  }
  
  input[type="submit"] {
    background-color: var(--submit-bg-color);
    color: var(--submit-text-color);
    border: none;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: var(--transition);
  }
  
  input[type="submit"]:hover {
    background-color: #3d1fc0;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 600px) {
    form {
      padding: 15px;
    }
  
    label {
      margin-bottom: 5px;
    }
  
    input[type="text"],
    input[type="email"],
    textarea {
      padding: 8px;
      margin-bottom: 10px;
    }
  
    input[type="submit"] {
      padding: 8px 16px;
    }
  }
  