.food-carousel {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(242, 117, 8)
  }
  
  .carousel-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .food-items {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .food-item {
    text-align: center;
    margin: 0 10px;
  }
  
  .food-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .food-item p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .nav-button {
    background: white;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .nav-button.prev {
    left: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    background-color: #4ba2b3;
  }
  
  .nav-button.next {
    right: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    background-color: #4ba2b3;
  }
  
  @media (max-width: 768px) {
    .food-items {
      justify-content: flex-start;
      overflow-x: auto;
    }
  
    .nav-button {
      display: none;
    }
  }