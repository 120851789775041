/* .map-container {
    width: 100%;
    max-width: 600px;
    height: 300px;
    margin: 0 auto;
    background-color: #e0e0e0;
    background-image: 
      linear-gradient(to right, #d3d3d3 1px, transparent 1px),
      linear-gradient(to bottom, #d3d3d3 1px, transparent 1px);
    background-size: 20px 20px;
    border: 2px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .map-container:hover {
    background-color: #d0d0d0;
    transform: scale(1.02);
  }
  
  .map-pin {
    width: 30px;
    height: 30px;
    background-color: #ff4444;
    border-radius: 50% 50% 50% 0;
    transform: rotate(-45deg);
    margin-bottom: 10px;
  }
  
  .map-text {
    font-family: Arial, sans-serif;
    font-size: 16px;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .map-container {
      height: 200px;
    }
  
    .map-pin {
      width: 20px;
      height: 20px;
    }
  
    .map-text {
      font-size: 14px;
    }
  } */







  /* .map-container {
    width: 100%;
    max-width: 600px;
    height: 300px;
    margin: 0 auto;
    background-color: #f0f0f0;
    background-image: 
      radial-gradient(circle at 1px 1px, #d3d3d3 1px, transparent 0);
    background-size: 20px 20px;
    border: 2px solid #ccc;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .map-container:hover {
    background-color: #e8e8e8;
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .map-icon {
    width: 48px;
    height: 48px;
    fill: #4285F4;
    margin-bottom: 10px;
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));
  }
  
  .map-text {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .map-container {
      height: 200px;
    }
  
    .map-icon {
      width: 36px;
      height: 36px;
    }
  
    .map-text {
      font-size: 14px;
    }
  } */



  /* 3rd one */
/*  */

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.map-container:hover {
  transform: scale(1.05);
}

.map-circle {
  background: linear-gradient(135deg, #4285F4, #34A853);
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.map-circle:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.map-icon-container {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.map-icon {
  fill: white;
  width: 100%;
  height: 100%;
}

.map-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  max-width: 80%;
}

@media (max-width: 768px) {
  .map-circle {
    width: 150px;
    height: 150px;
  }

  .map-icon-container {
    width: 60px;
    height: 60px;
  }

  .map-text {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .map-circle {
    width: 120px;
    height: 120px;
  }

  .map-icon-container {
    width: 50px;
    height: 50px;
  }

  .map-text {
    font-size: 12px;
  }
}